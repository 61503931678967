import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
     <img src='/TechYnnovativelogo.png' style={{ height:'100vh'}}></img>
    </div>
  );
}

export default App;
